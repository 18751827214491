import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/contact/hero"
import ContactSection from "../components/contact/contact"
import ReferralForm from "../components/contact/referral_form"

const TermsOfUse = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="container termsPage">
        <h1> Terms of Use </h1>
        <p>Updated: June 1, 2022</p>
        <p>This website or application (“Site”) is owned and operated by Canton Animal Hospital PLLC and/or one or more of its direct or indirect subsidiaries and affiliates (collectively, the “Company,” “we” or “us”).</p>
        <p>1. Acceptance of Terms of Use. Please read these Terms of Use carefully. These Terms of Use set forth the terms and conditions on which you may access this Site and any content, functionality, and services offered or otherwise made available to you through this Site. These Terms of Use also contain provisions that limit our liability to you. By browsing or using this Site, you accept and agree to these Terms of Use. If you do not want to agree to these Terms of Use, please do not access or use this Site.</p>
        <p>2. This Site Does Not Provide Medical Advice. The contents of this Site are available to you for informational purposes only, including text, graphics, images, and other information provided by us or licensed from third parties on this Site. Although the Site provides educational information about clinical research and health care issues, it does not provide medical diagnoses or recommendations regarding an individual’s medical treatment. Please do not make decisions about your health care in reliance on the information provided through this Site. Instead, always seek the advice of your physician or qualified health care provider with any questions you may have regarding a medical condition or treatment options. In an emergency, call 911 or your local emergency assistance.</p>
        <p>3. Privacy. Our Privacy Notice describes how we use and share the personal information we collect from users of our Site. The Privacy Notice is available for review on this Site and, by using this Site, you acknowledge receipt of our Privacy Notice. In consideration of your use of the Site and access to the Content, you agree to the terms of our Privacy Notice.</p>
        <p>4. Minors. Our Site is intended for adults and is not intended for minors. We do not knowingly collect personally identifiable information from minors on this Site. If you are under the age of 18, you are not eligible to use this Site or provide any information on this Site, including your name, address, and email address.</p>
        <p>5. Other Terms and Conditions. Additional rules, terms, and conditions for the use of certain features on this Site, such as certain software or mobile applications, may be included elsewhere within this Site and are incorporated into these Terms of Use by reference.</p>
        <p>6. Intellectual Property Rights. Except as noted below, the Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, graphics, images, illustrations, photographs, video, audio, and other materials and the design, selection, and arrangement thereof) (the “Content”), are owned by us, our licensors, or other providers of such material and are protected by the United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. Certain products, services, or company designations for companies other than us may be mentioned in this Site for identification purposes only.</p>
        <p>Third-party trademarks, trade names, logos, product, or service names contained on this Site are the trademarks, registered or unregistered, of their respective owners.</p>
        <p>Any use of this Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws. If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of this Site in breach of these Terms of Use, your right to use this Site will cease immediately and you must, at our option, return or destroy any copies of the Content you have made. Neither these Terms of Use, nor your use of this Site, transfers any right, title, or interest in this Site or the Content to you, and we and our third-party licensors retain all of our and their respective rights, title, and interest to this Site and the Content. Any rights not expressly granted herein are reserved.</p>
        <p>7. Use of this Site and the Content. These Terms of Use permit you to view this Site for your personal, informational, and non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the Content on our Site, except as follows:</p>
        <p>your computer may temporarily store copies of such Content in RAM incidental to your accessing and viewing any Content;</p>
        <p>you may store files that are automatically cached by your browser for display enhancement purposes; or </p>
        <p>you may print or download one copy of a reasonable number of pages of this Site for your own personal, non-commercial use, and not for further reproduction, publication, or distribution if you include the copyright notice and information found in Section 28 of these Terms of Use and other copyright and proprietary rights notices that are contained in the Content.</p>
        <p>8. Prohibited Uses of this Site and the Content. You may use this Site only for lawful purposes and in accordance with these Terms of Use. In accessing or using this Site, you agree that you will not: delete, modify, hack, or attempt to change or alter any of the Content on this Site;</p>
        <p>use any Content, including illustrations, photographs, video, or audio sequences, or any graphics, appearing in this Site separately from the accompanying text;</p>
        <p>delete or alter any copyright, trademark, or other proprietary rights notices from copies of any Content from this Site;</p>
        <p>access or use for any commercial purposes any part of this Site, or any services, or any Content made available through or on this Site;</p>
        <p>use any device, software, or routine intended to damage or otherwise interfere with the proper functioning of this Site or servers or networks connected to this Site, or take any other action that interferes with other parties’ use of this Site;</p>
        <p>use any robot, spider, or other automatic, or manual device, or process to access this Site for any purpose, including monitoring or copying any of the Content on this Site other than for a generally available search engine;</p>
        <p>use any copyrights, service marks, trademarks, or Content owned by us or a third party without our prior written consent, including without limitation as metatags, search engine keywords, or hidden text;</p>
        <p>use any Content made available through this Site in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party;</p>
        <p>upload files or otherwise introduce files that contain viruses, malware, Trojan horses, worms, corrupted files, or any other similar software or programs that may damage the operation of another’s computer or property of another;</p>
        <p>in any way violate any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries);</p>
        <p>transmit or send any advertising or promotional material, including any “junk mail,” “chain letter,” or “spam,” or any other similar solicitation;</p>
        <p>impersonate or attempt to impersonate or “spoof” us, any of our employees, another user, or any other person, or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing);</p>
        <p>attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of this Site, the server(s) on which this Site is stored, or any server, computer, or database connected to this Site or the security measures deployed to protect them;</p>
        <p>attack this Site via a denial-of-service attack or a distributed denial-of-service attack; or</p>
        <p>engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of this Site, or which, as determined by us, may harm us or other users of this Site or expose them to liability.</p>
        <p>9. Linking to this Site and Social Media Features. You may link or hyperlink any publicly available page of this Site to a website that is owned by you; provided that you do so in a way that is fair and legal, does not damage or take advantage of our reputation, does not violate any of our intellectual property rights or the intellectual property rights of others, and does not cause us to violate any laws; and provided, further that you do not:</p>
        <p>establish a link from any website that is not owned by you or that would violate that website’s Terms of Use;</p>
        <p>cause this Site, the Content, or portions of this Site or the Content to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site;</p>
        <p>use a link to this Site that in any way suggests that we are associated with, approve, or endorse you, or your website, or the content appearing on your website, except with our prior express written consent;</p>
        <p>use or present this Site or Content in any way that disparages us or tarnishes, blurs, or dilutes the quality of our copyrights, trademarks, or other intellectual property, including any associated goodwill;</p>
        <p>display a link to this Site on any web page that displays objectionable content or links, including but not limited to, any content or information that: (i) is libelous or defamatory, pornographic, sexually explicit, unlawful, or plagiarized; (ii) a reasonable person would consider harassing, abusive, threatening, harmful, profane, obscene, racially, ethnically, or otherwise objectionable, or offensive in any way; (iii) constitutes a breach of any person’s privacy or publicity rights, a misrepresentation of facts, hate speech, or an infringement of any third party’s intellectual property or proprietary rights; or (iv) violates or encourages others to violate any applicable law; or</p>
        <p>otherwise take any action with respect to the Content on this Site that is inconsistent with any other provision of these Terms of Use</p>
        <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.</p>
        <p>Further, this Site may provide certain social media features that enable you to:</p>
        <p>link from your own or certain third-party websites to certain content on this Site;</p>
        <p>send e-mails or other communications with Content, or links to Content, on this Site; or</p>
        <p>cause limited portions of the Content on this Site to be displayed or appear to be displayed on your own or certain third-party websites.</p>
        <p>You may use these features solely as they are provided by us and solely with respect to the Content with which these features are displayed and otherwise in accordance with any additional terms and conditions we provide with respect to such features. The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the “Content Standards” section of these Terms of Use. We may disable all or any social media features and any links at any time without notice and at our sole discretion.</p>
        <p>10. User Accounts and Registration. To access this Site or some of the Content or other features it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of this Site that all the information you provide on this Site is correct, current, and complete. In addition, you may be asked to establish a username, password, or any other piece of information to access Content or establish an account on this Site as part of our security procedures. It is ia condition of your use of this Site that you treat such information as confidential and not disclose it to any other person or entity. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view your account or other personal information. We have the right to disable any username, password or other identifiers, whether chosen by you or provided by us, at any time in our sole discretion for any reason. This includes if we have determined that you have violated any provision of these Terms of Use.</p>
        <p>11. Interactive Features and User Contributions. The Site may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, or other interactive features (collectively, “Interactive Features”) that allow users to upload, post, submit, publish, display, or transmit Content to us and other users of this Site or other persons (collectively, “User Contributions”) on or through this Site. All User Contributions must comply with the Content Standards contained in these Terms of Use.</p>
        <p>We do not claim ownership of any User Contributions or other information or material that a user provides to us or posts, uploads, inputs or submits to or through this Site by any means. All User Contributions will be treated as non-confidential and non-proprietary and may be disseminated or used by us for any purpose whatsoever and without restrictions, except where prohibited by law.</p>
        <p>By initiating or making any User Contribution, you automatically agree to grant us and our third-party business partners, and licensors, and each of our and their respective subsidiaries, affiliates, licensees, successors, and assigns an unrestricted, irrevocable, worldwide, royalty-free license to use, reproduce, modify, perform, display, distribute, create derivative works from, transmit, and distribute such User Contribution, alone or as part of other works in any form, media or technology whether now known or hereafter developed, for any purpose. You hereby waive all rights to any claim against us and our business partners and licensors and each of our and their respective subsidiaries, affiliates, licensees, successors, and assigns for any alleged or actual infringements of any proprietary rights, rights of privacy, rights of publicity, moral rights, and rights of attribution in connection with such User Contribution. Notwithstanding the foregoing, we are not required to use any User Contribution.</p>
        <p>You represent and warrant that you own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our third-party business partners and licensors and each of our and their respective subsidiaries, affiliates, licensees, successors, and assigns and that all of your User Contributions do and will comply with these Terms of Use.</p>
        <p>12. Content Standards. The content standards set forth in this section (the “Content Standards”) apply to any and all User Contributions, your use of any Interactive Features of this Site (except and to the extent modified by any additional terms and conditions that apply to the use of one or more Interactive Features) and to any links or hyperlinks to this Site or Content as described in the “Linking to this Site and Certain Social Media Features” section above. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
        <p>contain any defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable material, or statements;</p>
        <p>promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;</p>
        <p>infringe any patent, trademark, trade secret, copyright, or other intellectual property, or other rights of any other person;</p>
        <p>violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use;</p>
        <p>be likely to deceive any person;</p>
        <p>promote any illegal activity, or advocate, promote, or assist any unlawful act;</p>
        <p>cause annoyance, inconvenience, or needless anxiety, or be likely to upset, embarrass, alarm, or annoy any other person;</p>
        <p>impersonate any person, or misrepresent your identity or affiliation with any person or organization;</p>
        <p>involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter or advertising; or</p>
        <p>give the impression that they emanate from or are endorsed by us or any other person or entity if this is not the case.</p>
        <p>13. Monitoring and Enforcement. We do not undertake to review User Contributions or other material before it is posted on this Site, and we cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we do not guarantee the truthfulness, accuracy, or reliability of information or material posted, uploaded, input, or submitted to this Site and we do not endorse any statements, representations, or opinions expressed, posted, uploaded, input, or submitted to this Site by any user. Your reliance on information or material on this Site shall be at your own risk.</p>
        <p>If we are notified of information or material posted, uploaded, input, or submitted to this Site allegedly in violation of these Terms of Use, we may exercise our sole discretion whether to remove such information or material. We have a right to:</p>
        <p>remove or refuse to post any User Contributions for any or no reason in our sole discretion;</p>
        <p>take any action with respect to any User Contributions that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contributions violate the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of this Site, or the public, or could create liability for us;</p>
        <p>disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy;</p>
        <p>take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of this Site or any Content; and</p>
        <p>terminate or suspend your access to all or part of this Site for any or no reason, including without limitation, any violation of these Terms of Use.</p>
        <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through this Site or accessing any Interactive Features.</p>
        <p>YOU WAIVE AND AGREE TO HOLD US AND OUR THIRD-PARTY BUSINESS PARTNERS AND LICENSORS AND EACH OF OUR AND THEIR RESPECTIVE SUBSIDIARIES, AFFILIATES, LICENSEES, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, SUCCESSORS, AND ASSIGNS HARMLESS FROM AND INDEMNIFY US AGAINST, ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES, ANY OTHER PERSON, OR ANY GOVERNMENT AGENCY AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY SUCH PARTIES, ANY OTHER PERSON, OR LAW ENFORCEMENT AUTHORITIES.</p>
        <p>14. Copyright Infringement. We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law and this section of these Terms of Use. In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), if you believe any Content accessible on or from this Site infringes your copyright, you may request removal of that Content (or access thereto) from this Site by submitting a written notice sent via a recognized overnight carrier service to our Copyright Agent designated below. Such notice must include substantially the following information (the “DMCA Notice”):</p>
        <p>your physical or electronic signature;</p>
        <p>identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on this Site, a representative list of such works;</p>
        <p>identification of the Content or activity you believe to be infringing in a sufficiently precise manner to allow us to locate that Content or activity;</p>
        <p>adequate information by which we can contact you (including your name, postal address, telephone number, and if available, e-mail address);</p>
        <p>a statement that you have a good faith belief that the use of the copyrighted Content is not authorized by the copyright owner, its agent, or the law;</p>
        <p>a statement that the information in the written notice is accurate; and</p>
        <p>a statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</p>
        <p>Our designated Copyright Agent to receive DMCA Notices is:</p>
        <p>General Counsel</p>
        <p>Canton Animal Hospital PLLC</p>
        <p>43439 Michigan Ave</p>
        <p>Canton, MI 48198</p>
        <p>If you fail to comply with all the foregoing requirements (as more fully set forth in Section 512(c)(3) of the DMCA), your DMCA Notice may not be effective.</p>
        <p>Please be aware that if you knowingly materially misrepresent that any Content or any activity on the Site is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA. It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.</p>
        <p>15. Termination or Modifications to this Site. We reserve the right to modify or terminate your access to this Site (or portions of this Site) at any time, temporarily or permanently, with or without notice to you. From time to time, we may restrict access to some parts of this Site, or the entire Site, to users, or charge fees for access to portions of this Site. You acknowledge and agree that we will not be liable to you or any third party in the event that we exercise our right to modify or terminate access to this Site.</p>
        <p>16. Modifications to these Terms of Use. We may change these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to, and use of, this Site thereafter. Your continued use of this Site following the posting of any updates or changes to these Terms of Use constitutes your acceptance of such changes. If you object to any provisions of these Terms of Use or any subsequent modifications to these Terms of Use, your recourse is to immediately terminate use of this Site.</p>
        <p>17. Linked Sites and Third Party Content, Products, and/or Services. As a convenience to you, this Site may provide links to websites and access to material, products, and services of third parties, including without limitation, our third-party business partners and licensors, and each of our and their respective subsidiaries, affiliates, licensees, successors, and assigns with which our connection consists of only a hyperlink, including links contained in advertisements, including banner advertisements and sponsored links (“Linked Sites”).</p>
        <p>We do not author, edit, or monitor these Linked Sites. We have no control over the contents of those Linked Sites and accept no responsibility for them or for any loss or damage that may arise from your use of them or in your dealings with any third parties found on or through this Site. The inclusion of any link on this Site does not imply endorsement of the Linked Sites by us or vice versa.</p>
        <p>You bear all risk associated with the use of Linked Sites, third-party services, and your correspondence or business dealings with advertisers found on or through this Site. If you decide to access Linked Sites, you do so entirely at your own risk and subject to the terms and conditions of use for such websites. You should refer to the separate terms of use, privacy policies, and other rules posted on the Linked Sites before you use them.</p>
        <p>This Site may include content provided by third parties, including Content, User Contributions, and other information and materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services (“Third Party Content”). All statements and/or opinions expressed in such Third Party Content, and all articles and responses to questions and other content relating to any such Third Party Content are solely the opinions and the responsibility of the person or entity providing those materials. Third-Party Content does not necessarily reflect our opinion. In addition, we do not warrant the accuracy, completeness or usefulness of any Third Party Content made available on or through this Site. Any reliance you place on such Third Party Content is strictly at your own risk. We are not responsible, or liable to you or any third party, for the Content or accuracy of any Third Party Content. We disclaim all liability and responsibility arising from any reliance placed on such Third Party Content by you or any other visitor to this Site, or by anyone who may be informed of any of such Third Party Content.</p>
        <p>18. Typographical Errors. Our goal is to provide complete, accurate, up-to-date information on our Site. Unfortunately, it is not possible to ensure that any website is completely free of typographical mistakes, inaccuracies, or omissions. Some information may not be complete or current. We, therefore, reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice.</p>
        <p>19. Forward-Looking Statements. This Site contains forward-looking statements based on the beliefs of our management. When used in this Site, words such as “anticipate”, “believe”, “estimate”, “expect”, “intend”, “plan”, and “project” are intended to identify forward-looking statements. These forward-looking statements may involve risks and uncertainties, including technological advances in the medical field, product demand, and market acceptance, the effect of economic conditions, the impact of competitive products and pricing, foreign currency exchange rates, and other risks. These forward-looking statements reflect our views as of the date made with respect to future events and are subject to risks and uncertainties. All of these forward-looking statements are based on estimates and assumptions made by the management of the Company and are believed to be reasonable, though are inherently uncertain and difficult to predict. Actual results or experience could differ materially from the forward-looking statements. The Company disclaims any intention or obligation to update these forward-looking statements. None of the Content should be considered to be investment advice and the Company disclaims any liability relating to actions taken in reliance on Content in the Site.</p>
        <p>20. Disclaimer of Warranties. You understand that we cannot and do not guarantee or warrant that files available for downloading from the Internet or this Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Site for any reconstruction of the lost data.</p>
        <p>TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, THIS SITE AND ALL CONTENT, MATERIALS, INFORMATION, PRODUCTS, ITEMS, AND SERVICES PROVIDED ON THIS SITE ARE PROVIDED ON AN “AS IS, WHERE IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS AND WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
        <p>NEITHER WE NOR ANY PERSON OR ENTITY ASSOCIATED OR AFFILIATED WITH US MAKE ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THIS SITE.</p>
        <p>WITHOUT LIMITING THE FOREGOING, NEITHER WE NOR ANY PERSON OR ENTITY ASSOCIATED OR AFFILIATED WITH US REPRESENTS OR WARRANTS THAT: (A) THIS SITE WILL MEET YOUR REQUIREMENTS OR WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (B) THE CONTENT WILL BE UP-TO-DATE, COMPLETE, COMPREHENSIVE, OR ACCURATE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS SITE OR ANY CONTENT, MATERIALS, INFORMATION, PRODUCTS, ITEMS, OR SERVICES OFFERED THROUGH THIS SITE OR BY THE COMPANY WILL BE ACCURATE OR RELIABLE; (D) THE QUALITY OF ANY CONTENT, MATERIALS, INFORMATION, PRODUCTS, ITEMS OR SERVICES OBTAINED BY YOU THROUGH THIS SITE WILL MEET YOUR EXPECTATIONS; OR (E) THAT DEFECTS, IF ANY, WILL BE CORRECTED.</p>
        <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        <p>21. Limitation of Liability. IN NO EVENT SHALL WE OR ANY OF OUR THIRD PARTY BUSINESS PARTNERS, LICENSORS, SUBSIDIARIES, OR AFFILIATES AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, SUCCESSORS, AND ASSIGNS BE LIABLE FOR ANY DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS OR USE, OR INABILITY TO ACCESS OR USE, THIS SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THIS SITE, OR SUCH OTHER WEBSITES, OR ANY PRODUCTS, OR SERVICES, OR ITEMS OBTAINED THROUGH THIS SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN, AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT. OR OTHERWISE (INCLUDING NEGLIGENCE AND STRICT LIABILITY), EVEN IF FORESEEABLE OR IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <p>SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OR EXCLUSIONS OF LIABILITY IN SOME CIRCUMSTANCES. CONSEQUENTLY, SOME OF THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.</p>
        <p>22. Indemnity. You agree to indemnify, defend, and hold us and our third-party business partners and licensors and each of our and their respective subsidiaries, affiliates, licensees, successors, and assigns and our and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns, harmless from any claim, demand, liability, dispute, damage, cost, expense, or loss, including attorneys’ fees and costs of litigation, arising out of or in any way related to your violation of these Terms of Use or your access or use of this Site, the Content, Interactive Features, or any User Contributions (including any use or access by your employees or agents), your violation of these Terms of Use or your violation of any rights of another.</p>
        <p>23. Governing Law and Jurisdiction. All matters relating to this Site and these Terms of Use and any dispute or claim arising from them shall be governed by and construed in accordance with the laws of the State of Florida without giving effect to any choice or conflict of law provision. Any legal suit, action, or proceeding against the Company arising out of, or related to, these Terms of Use or this Site shall be instituted exclusively in the applicable federal courts of the United States or the courts of the State of Florida, in either case in courts sitting in Broward County, Florida. We retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your state, province and/or country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
        <p>24. Limitation on Time to File Claims. YOU AGREE THAT ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF YOUR USE OF THIS SITE OR THESE TERMS OF USE MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR IT SHALL FOREVER BE BARRED, NOTWITHSTANDING ANY STATUTE OF LIMITATIONS OR OTHER LAW TO THE CONTRARY. Within this period, any failure by us to enforce or exercise any provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.</p>
        <p>25. Geographic Restrictions. This Site and the Terms of Use are governed by United States law, and we make no representation that this Site is appropriate or available for use in locations outside of the United States. If you access this Site from outside the United States, the laws of the United States may be different from those in your country, and you understand that by using this Site, your personal information will be processed in the United States and in other countries where our vendors are located. If you access this Site from outside the United States, you do so on your own initiative and are responsible for compliance with local laws. We make no representation that this Site, or any of the Content, is appropriate or available for use in locations outside of the United States.</p>
        <p>26. Acknowledgement and Entire Agreement. You acknowledge that you have read and understood these Terms of Use and, in consideration of your access and use of the Site, agree that these Terms of Use have the same force and effect as a signed agreement. These Terms of Use constitute the sole and entire agreement between you and us with respect to this Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to this Site.</p>
        <p>27. Waiver and Severability. Our failure to act with respect to a breach by any user of this Site does not constitute a waiver of our right to act with respect to subsequent or similar breaches. No waiver by us of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of us to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. In the event that any provision of these Terms of Use is invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent possible and the remainder of these Terms of Use will remain in full force and effect.</p>
        <p>28. Contact. If you have any questions about these Terms of Use or your dealings with this Site, please contact us as follows:</p>
        <p>Canton Animal Hospital PLLC <br/>
          Attn: General Counsel <br />
          43439 Michigan Ave, <br />
          Canton, MI 48188 <br />
          info@cantonvets.com <br />
          Please make sure that all inquiries are clearly marked “Attn: General Counsel.”
        </p>
      </div>
    </Layout>
  )
}

export default TermsOfUse